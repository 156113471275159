import { ActionType2, AdvanceCategorizationType, RulesTxnType } from './constants';

export type RuleKey = 'id' | 'disabled' | 'name' | 'priority' | 'description';

export type ConditionKey =
  | 'walletIds'
  | 'assets'
  | 'multiToken'
  | 'methodId'
  | 'direction'
  | 'date'
  | 'fromAddress'
  | 'toAddress'
  | 'subTxnFromAddress'
  | 'subTxnToAddress'
  | 'transactionType'
  | 'autoCategorizeFee'
  | 'metadataRule';

export type AdvancedConditionKey = 'identifiers' | 'assetQty';

export type ActionKey =
  | 'advanceCategorize'
  | 'accountingConnectionId'
  | 'collapseValues'
  | 'ignoreFailPricing'
  | 'categoryId'
  | 'contactId'
  | 'feeCategoryId'
  | 'feeContactId'
  | 'deFiWalletId'
  | 'percentageSplits'
  | 'feePercentageSplits'
  | 'lines';

export type ConditionFields = {
  [key in ConditionKey]: {
    label?: string;
    expanded?: boolean;
    alwaysVisible?: boolean;
    formDataKeys?: string[]; // when field key is different from form data key
    required?: boolean;
    featureFlag?: string;
  };
};

export type ActionFields = {
  [key in ActionKey]: {
    label: string;
    applicable?: boolean;
    actionsWhenApplicable?: ActionType2[];
    txnTypesWhenApplicable?: RulesTxnType[];
    advCatWhenApplicable?: AdvanceCategorizationType[]; // only when advancedCategoriztion is in actionsWhenApplicable
    required?: boolean;
  };
};

export const conditions: ConditionFields = {
  walletIds: {
    label: 'Wallet',
    expanded: false,
  },
  assets: {
    label: 'Asset',
    expanded: false,
  },
  multiToken: {
    alwaysVisible: true,
  },
  methodId: {
    label: 'Method ID',
    expanded: false,
  },
  direction: {
    label: 'Direction',
    expanded: false,
    alwaysVisible: true,
    required: true,
  },
  date: {
    label: 'Date',
    expanded: false,
    formDataKeys: ['afterDate', 'beforeDate'],
  },
  fromAddress: {
    label: 'From Address',
    expanded: false,
  },
  toAddress: {
    label: 'To Address',
    expanded: false,
  },
  subTxnFromAddress: {
    label: 'Sub Txn From Address',
    expanded: false,
  },
  subTxnToAddress: {
    label: 'Sub Txn To Address',
    expanded: false,
  },
  metadataRule: {
    label: 'Metadata Filter',
    expanded: false,
    formDataKeys: ['selectedMetadataField', 'metadataInput'],
    featureFlag: 'RulesByMetadata',
  },
  transactionType: {
    label: 'Transaction Type',
    alwaysVisible: true,
  },
  autoCategorizeFee: {
    alwaysVisible: true,
  },
};

export const advancedConditions: any = {
  assetQty: {
    label: 'Asset Quantity',
    expanded: false,
    formDataKeys: ['fromAssetQty', 'toAssetQty'],
  },
  identifiers: {
    label: 'Use identifiers',
    expanded: false,
  },
};

export const actions: ActionFields = {
  advanceCategorize: {
    label: 'Advance Categorize',
    actionsWhenApplicable: ['advancedCategorize'],
  },
  accountingConnectionId: {
    label: 'Accounting Connection',
  },
  collapseValues: {
    label: 'Collapse Values',
    actionsWhenApplicable: ['categorize', 'advancedCategorize'],
    txnTypesWhenApplicable: ['standard', 'internalTransfer'],
    advCatWhenApplicable: ['split'],
  },
  ignoreFailPricing: {
    label: 'Ignore Fail Pricing',
    actionsWhenApplicable: ['categorize', 'advancedCategorize'],
    txnTypesWhenApplicable: ['standard', 'internalTransfer', 'trade'],
    advCatWhenApplicable: ['detailed'],
  },
  categoryId: {
    label: 'Category Type',
    actionsWhenApplicable: ['categorize'],
    txnTypesWhenApplicable: ['standard'],
    required: true,
  },
  contactId: {
    label: 'Customer / Vendor',
    actionsWhenApplicable: ['categorize'],
    txnTypesWhenApplicable: ['standard'],
    required: true,
  },
  feeCategoryId: {
    label: 'Fee Category',
    actionsWhenApplicable: ['categorize'],
    txnTypesWhenApplicable: ['standard', 'feeOnly'],
  },
  feeContactId: {
    label: 'Fee Contact',
    actionsWhenApplicable: ['categorize', 'advancedCategorize'],
    txnTypesWhenApplicable: ['standard', 'trade', 'internalTransfer', 'feeOnly'],
    advCatWhenApplicable: ['defi'],
    required: true,
  },
  deFiWalletId: {
    label: 'Defi Wallet',
    actionsWhenApplicable: ['advancedCategorize'],
    txnTypesWhenApplicable: ['standard'],
    advCatWhenApplicable: ['defi'],
    required: true,
  },
  percentageSplits: {
    label: 'Splits',
    actionsWhenApplicable: ['advancedCategorize'],
    txnTypesWhenApplicable: ['standard'],
    advCatWhenApplicable: ['split'],
  },
  feePercentageSplits: {
    label: 'Fee Splits',
    actionsWhenApplicable: ['advancedCategorize'],
    txnTypesWhenApplicable: ['standard'],
    advCatWhenApplicable: ['split'],
  },
  lines: {
    label: 'Lines',
    actionsWhenApplicable: ['advancedCategorize'],
    txnTypesWhenApplicable: ['standard'],
    advCatWhenApplicable: ['detailed'],
  },
};

export function isConditionKey(key: string): key is ConditionKey {
  return ['fromAddress', 'toAddress', 'multiToken', 'methodId', 'direction', 'autoCategorizeFee'].includes(key);
}

export function isRuleKey(key: string): key is RuleKey {
  return ['id', 'disabled', 'name', 'priority', 'description'].includes(key);
}

export const conditionMetadataFields = [
  { id: 'DestinationTag', label: 'Destination Tag' },
  { id: 'FeeType', label: 'Fee Type' },
  { id: 'TransactionType', label: 'Transaction Type' },
  { id: 'RewardType', label: 'Reward Type' },
  { id: 'RewardFeeType', label: 'Reward Fee Type' },
  { id: 'exampleMetadataKey', label: 'Example Metadata Key', featureFlag: 'exampleDontSet' },
  { id: 'geneva_curr', label: 'Geneva Currency' },
  { id: 'trantype', label: 'Trantype' },
  { id: 'geneva_price', label: 'Geneva Price' },
  { id: 'eventId', label: 'Event Id' },
  { id: 'custodian_source', label: 'Custodian Source' },
  { id: 'strat', label: 'FundStrategy' },
  { id: 'staking_source', label: 'Staking Source' },
];
