














































import axios from 'axios';
import { Component, Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import { baConfig } from '../../../config';
import UiButton from '../ui/UiButton.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';

@Component({
  components: {
    UiTextEdit,
    UiButton,
  },
})
export default class RuleValidation2 extends BaseVue {
  @Prop()
  readonly ruleId?: string;

  public validation = {
    isValidating: false,
    validated: false,
    txnId: '',
    errors: [] as string[],
  };

  public async validate() {
    if (!this.ruleId) return;

    try {
      this.validation.isValidating = true;
      this.validation.validated = false;
      this.validation.errors = [];

      const orgId = this.$store.state.currentOrg.id;
      const url = `${baConfig.apiUrl}orgs/${orgId}/transactions/${encodeURIComponent(this.validation.txnId)}/rules/${
        this.ruleId
      }`;
      const resp = (await axios({ url, withCredentials: true })).data;

      if (!resp.result) {
        this.validation.errors = ['Could not check transaction, please make sure the transaction id is correct.'];
        return;
      }

      if (resp.result.error) {
        this.validation.errors = resp.result.error.split(';');
      }
    } catch (error) {
      this.validation.errors = ['Problem checking transaction, please try again later.', `${error}`];
    } finally {
      this.validation.isValidating = false;
      this.validation.validated = true;
    }
  }
}
